<template>
  <div class="footer">
    <v-container>
      <v-row class="d-none d-md-flex">
        <v-col cols="12" md="6" class="pr-10">
          <h5 class="subtitle mb-0 mt-2 text-right">
            {{ $t("footer.lookingForward") }}
          </h5>
        </v-col>

        <v-col cols="12" md="6" class="pl-10"
               style="margin: auto 0">
          <a class="mail-to text-h4 font-weight-bold"
              href="mailto:kontakt@benjamin-homm.de">
            kontakt@benjamin-homm.de
          </a>
        </v-col>
      </v-row>

      <v-row class="d-md-none">
        <v-col cols="12" md="6">
          <h5 class="subtitle mb-0">
            {{ $t("footer.lookingForward") }}
          </h5>
        </v-col>

        <v-col cols="12" md="6" class="text-center"
               style="margin: auto 0">
          <a class="mail-to text-h5 font-weight-bold"
              href="mailto:kontakt@benjamin-homm.de">
            kontakt@benjamin-homm.de
          </a>
        </v-col>
      </v-row>

      <!-- <div class="d-flex justify-center mt-15">
        <a href="https://www.linkedin.com/in/benjamin-homm-705b85251/" class="mx-3 icon-socialmedia"
           target="_blank">
          <v-icon>mdi-linkedin</v-icon>
        </a>

        <a href="https://www.xing.com/profile/Benjamin_Homm2" class="mx-3 icon-socialmedia"
           target="_blank">
          <v-icon>mdi-xing</v-icon>
        </a>
      </div> -->

      <v-divider class="margin-regular"/>

      <div class="d-flex justify-center">
        <router-link :to="{name: 'impress'}" class="mx-5 link">
          {{ $t("footer.impress") }}
        </router-link>

        <router-link :to="{name: 'privacy'}" class="mx-5 link">
          {{ $t("footer.privacy") }}
        </router-link>
      </div>

      <div class="text-center my-10">
        <v-btn variant="outlined" icon size="large"
               @click="scrollToTop">
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
      </div>

      <p class="mt-5 text-body-2 text-center">2022 <v-icon size="x-small">mdi-copyright</v-icon> 
          Benjamin Homm's UX Design Portfolio - Ingolstadt, Germany 🇩🇪</p>
    </v-container>
  </div>
</template>

<script>
  import $ from 'jquery'
  export default {
    methods: {
      scrollToTop() {
        $("html, body").animate({ scrollTop: 0}, "slow");
      }
    },
  };
</script>