import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/work',
    name: 'work',
    component: () => import(/* webpackChunkName: "about" */ '../views/WorkView.vue')
  },
  {
    path: '/work/betterfly',
    name: 'betterfly',
    component: () => import(/* webpackChunkName: "about" */ '../views/projects/BetterflyView.vue')
  },
  {
    path: '/work/foodar',
    name: 'foodar',
    component: () => import(/* webpackChunkName: "about" */ '../views/projects/FoodarView.vue')
  },
  {
    path: '/work/contact-app',
    name: 'contactapp',
    component: () => import(/* webpackChunkName: "about" */ '../views/projects/ContactAppView.vue')
  },
  {
    path: '/impress',
    name: 'impress',
    component: () => import(/* webpackChunkName: "about" */ '../views/ImpressView.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "about" */ '../views/PrivacyView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  next();
});

export default router
