import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainMenu = _resolveComponent("MainMenu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_FooterComp = _resolveComponent("FooterComp")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_MainMenu),
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_component_router_view, {
            key: _ctx.$route.fullPath
          }))
        ]),
        _: 1
      }),
      _createVNode(_component_FooterComp)
    ]),
    _: 1
  }))
}