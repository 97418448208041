import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "headline p-transition-y" }
const _hoisted_2 = { class: "subtitle mb-10 p-transition-y" }
const _hoisted_3 = { class: "skill-box p-transition-y" }
const _hoisted_4 = { class: "text-center" }
const _hoisted_5 = { class: "heading" }
const _hoisted_6 = { class: "ml-5 pl-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t("skills.mySkills")), 1),
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t("skills.coreCompetencies")), 1),
    _createVNode(_component_v_row, { class: "margin-b-large" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.coreSkills, (skill, i) => {
          return (_openBlock(), _createBlock(_component_v_col, {
            key: i,
            cols: "12",
            md: "4"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_v_icon, {
                    class: "mb-1 secondary-text",
                    size: "x-large"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(skill.icon), 1)
                    ]),
                    _: 2
                  }, 1024),
                  _createElementVNode("h3", _hoisted_5, _toDisplayString(skill.category), 1)
                ]),
                _createVNode(_component_v_divider),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(skill.skills, (item, i) => {
                  return (_openBlock(), _createElementBlock("ul", {
                    key: i,
                    class: "px-0 py-0 text-body-1",
                    style: {"min-height":"30px"}
                  }, [
                    _createElementVNode("li", _hoisted_6, _toDisplayString(item), 1)
                  ]))
                }), 128))
              ])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    })
  ], 64))
}