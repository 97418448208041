export default {
  "menu.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "menu.projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekte"])},
  "menu.about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über mich"])},
  "menu.contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
  "home.hello1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hallo 👋🏼, ich bin Benjamin Homm – UX/UI Designer mit langjähriger Erfahrung in den Bereichen App und Web."])},
  "home.hello2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich designe Anwendungen/Webseiten und setze diese mit State-of-the-Art Technologien um. Das Ergebnis sind digitale Produkte, die Ihre Anforderungen und Business Goals abdecken und dabei gerne von Nutzern bedient werden."])},
  "home.conceptOfSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Erfolgskonzept"])},
  "home.tech1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein"])},
  "home.techBold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" technisches Know-How "])},
  "home.tech2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ermöglicht es mir, Ihr Projekt direkt in die Tat umzusetzen."])},
  "home.needs1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verständnis für die"])},
  "home.needsBold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Bedürfnisse und Fähigkeiten der Nutzer "])},
  "home.needs2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entwickeln, um effektive Systeme zu designen."])},
  "home.business1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dabei stehen meine Lösungen stets im Einklang mit Ihren"])},
  "home.businessBold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Zielen und Geschäftsstrategien."])},
  "home.aboutMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über mich"])},
  "home.aboutMe1Bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über 5 Jahre Berufserfahrung "])},
  "home.aboutMe1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["als UX/UI-Designer."])},
  "home.aboutMe2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahrelange Erfahrung mit "])},
  "home.aboutMe2Bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["agilem Projektmanagement."])},
  "home.aboutMe3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahrelange Erfahrung mit "])},
  "home.aboutMe3Bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["interdisziplinären Teams."])},
  "home.briefInsight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein kurzer Einblick in meine Arbeit"])},
  "home.aboutMyProjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu meinen Projekten"])},
  "home.projectFlow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektablauf"])},
  "process.step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schritt 1"])},
  "process.title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Projektanfrage"])},
  "process.description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch die Anfrage erhalte ich ein grundlegendes Verständnis für das Projekt. Je mehr Infos ich zu Beginn bekomme, desto besser kann ich beurteilen, ob ich hierfür der richtige Partner bin."])},
  "process.step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schritt 2"])},
  "process.title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Erstgespräch"])},
  "process.description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im ersten Gespräch lernen wir uns kennen und besprechen die Ziele, Anforderungen und Rahmenbedingungen. Außerdem klären wir Fragen und definieren die nächsten Schritte."])},
  "process.step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schritt 3"])},
  "process.title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Angebot"])},
  "process.description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn beide Seiten eine klare Vorstellung von den Bedingungen und Zielen des Projekts haben, erstelle ich ein individuelles Angebot, welches wir anschließend gemeinsam besprechen und durchgehen."])},
  "process.step4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schritt 4"])},
  "process.title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Umsetzung"])},
  "process.description4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobald das Angebot angenommen wird, beginne ich mit der Umsetzung und absolviere die zuvor besprochenen Projektphasen. Dabei sind mir regelmäßige Updates während des gesamten Prozesses wichtig."])},
  "skills.mySkills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Skills"])},
  "skills.coreCompetencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die folgenden Punkte zählen zu meinen Kernkompetenzen"])},
  "tools.favoriteTools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das sind meine bevorzugten Tools für den kreativen Prozess"])},
  "footer.lookingForward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich freue mich schon auf Ihr Projekt."])},
  "footer.impress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
  "footer.privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutz"])},
  "projects.selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier ist eine Auswahl meiner Arbeit"])},
  "projects.furtherWork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere erwähnenswerte Projekte"])},
  "projects.secrecy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geheimhaltung"])},
  "projects.project1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Design für einen großen deutschen Discounter"])},
  "projects.project2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web-Anwendung für HR/Personalverwaltung"])},
  "projects.project3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web-Anwendung für EHS/Arbeitsschutz"])},
  "projects.project4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App zur Verbesserung des Workflows im Unternehmen - Bachelorarbeit"])},
  "projects.project5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web-Anwendung für User-Management für einen Automobilhersteller"])},
  "projects.project6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Improving the UX for Users of Automated Shuttle Buses in Public Transport: Investigating Aspects of Exterior Communication and Interior Design"])},
  "main.moreAbout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr über mich"])},
  "main.projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekte"])},
  "about.p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Name ist Benjamin und ich bin UX/UI-Designer aus Ingolstadt. Meine Fähigkeiten und Kenntnisse in diesem Bereich konnte ich mir durch mein Studium an der Technischen Hochschule in Ingolstadt aneignen. Hier machte ich meinen "])},
  "about.p1Bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bachelor und Master of Science in User Experience Design"])},
  "about.p2.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Während und nach meinem Studium arbeitete ich als "])},
  "about.p2Bold1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UX/UI-Designer "])},
  "about.p2.2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["für ein mittelständisches Unternehmen. Hier war ich insgesamt 5 Jahre Bestandteil des UX-Teams und war unter anderem für die "])},
  "about.p2Bold2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design-Strategie und Konzeption, sowie die Front-End Entwicklung "])},
  "about.p2.3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von Webanwendungen zuständig."])},
  "about.p3.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wichtig war hierbei auch die Zusammenarbeit und Kommunikation mit interdisziplinären Teams. Durch diese Erfahrungen in meinem Berufsleben kann ich mich gut in die "])},
  "about.p3Bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perspektiven der Entwickler und Designer "])},
  "about.p3.2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["versetzen und kann so nutzerzentriertes Design mit Technologien kombinieren."])},
  "about.p4.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seit Oktober 2022 arbeite ich freiberuflich als UX/UI-Designer und wechselte im Juni 2023 zur intive GmbH, wo ich als "])},
  "about.p4Bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UX Designer für die Entwicklung einer App "])},
  "about.p4.2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["für einen großen deutschen Discounter tätig war."])},
  "about.myCV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Lebenslauf"])},
  "about.career": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildungsweg und beruflicher Werdegang"])},
  "cv.intive.year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["06/2023 - 10/2023"])},
  "cv.intive.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UX Designer"])},
  "cv.intive.header1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Design"])},
  "cv.intive.bullet1.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wireframing & Prototyping"])},
  "cv.intive.bullet1.2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interface Design"])},
  "cv.intive.bullet1.3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design System"])},
  "cv.freelance.year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10/2022 - 10/2023"])},
  "cv.freelance.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freelancer"])},
  "cv.freelance.bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Research / Qualitative & Quantitative Research"])},
  "cv.freelance.bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wireframing & Prototyping"])},
  "cv.freelance.bullet3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interface / Interaction Design"])},
  "cv.uxprosis.year1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11/2021 - 10/2022"])},
  "cv.uxprosis.year2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["03/2019 - 03/2020"])},
  "cv.uxprosis.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UX Designer"])},
  "cv.uxprosis.header1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UX/UI-Design"])},
  "cv.uxprosis.bullet1.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interface Design & Konzeption"])},
  "cv.uxprosis.bullet1.2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guidelines & Design System"])},
  "cv.uxprosis.bullet1.3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellung von Icons"])},
  "cv.uxprosis.header2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frontend-Entwicklung"])},
  "cv.uxprosis.bullet2.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwicklung von Web-Anwendungen (JSF / Vue.js)"])},
  "cv.uxprosis.bullet2.2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwicklung und Verwaltung des eigenen Themes"])},
  "cv.master.year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["03/2020 - 11/2021"])},
  "cv.master.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master of Science - User Experience Design"])},
  "cv.master.header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurse"])},
  "cv.master.bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Research Methods in HCI, Programming for Multimodal Interactive Systems, Mobile App Development"])},
  "cv.master.bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interaction Design, Natural User Interfaces, Design Strategy and Management, Interface Design"])},
  "cv.master.bullet3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agile Project Management, Audio/Video Processing und 3D-Animation, Augmented und Virtual Reality Applications"])},
  "cv.scrum.year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["01/2020"])},
  "cv.scrum.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foundation in agile project management according to SCRUM"])},
  "cv.scrum.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3-tägige Schulung mit anschließender Prüfung"])},
  "cv.student.year1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["03/2020 - 11/2021"])},
  "cv.student.year2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["09/2017 - 03/2019"])},
  "cv.student.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werkstudent - UX Designer"])},
  "cv.bachelor.year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10/2015 - 03/2019"])},
  "cv.bachelor.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bachelor of Science - User Experience Design"])},
  "cv.bachelor.header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurse"])},
  "cv.bachelor.bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestaltung, Webdesign und Webusability, Designing Interactive Products, Produktdesign"])},
  "cv.bachelor.bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software Prototyping und Usability Testing, Informationspsychologie, Design von Mensch-Maschine-Schnittstellen"])},
  "cv.bachelor.bullet3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Softwareentwicklung, Webtechnologien, Software Engineering, Technik der Mensch-Maschine-Interaktion, Virtual und Augmented Reality"])},
  "cv.bachelor.bullet4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistik, Betriebswirtschaftliche Grundlagen und Personalorganisation, Soft Skills, Marketing, Projektmanagement"])},
  "project.main.about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über das Projekt"])},
  "project.main.goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziel"])},
  "project.main.role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle"])},
  "project.main.period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitraum"])},
  "project.main.type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
  "project.main.analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse"])},
  "betterfly.about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Arbeit befasst sich mit der Konzeption und Gestaltung einer mobilen Anwendung zur Unterstützung von jungen Erwachsenen mit Depressionen. Das Ziel dieser Anwendung ist es, die Betroffenen durch den Einsatz von Gamification bestmöglich bei der Selbstfürsorge zu unterstützen. Der gesamte Prozess wird nach dem Ansatz des User-Centered Design (UCD) durchgeführt."])},
  "betterfly.problem1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt eine Liste von Dingen, von denen ich weiß, dass ich sie tun kann, um das Gefühl zu bekommen etwas erreicht zu haben, damit ich mich körperlich und geistig besser fühle. Das Problem ist, dass sich diese Dinge in einer Depression wie eine unmögliche Aufgabe anfühlen."])},
  "betterfly.problem2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Zitat einer depressiven Frau zeigt, dass die meisten Betroffenen sehr wohl wissen, dass Selbstfürsorge wichtig ist, um die Depression zu überwinden und damit wieder gesund zu werden. Einerseits muss man Dinge aus seinem Leben entfernen, die schädlich sind. Andererseits ist es wichtig, sich Dinge zu gönnen, die einem gut tun. Hier liegt das Problem, denn vielen Betroffenen fällt es nicht leicht zu sagen, was ihnen persönlich gut tut und was ihre schlechte Laune lindert. Außerdem leiden depressive Menschen unter Antriebslosigkeit und unter dem Verlust von Lebensfreude und Interesse. Depressiv zu sein geht weit über schlechte Laune hinaus und führt dazu, dass man keinen Sinn mehr in Aktivitäten sieht und sich hilflos fühlt."])},
  "betterfly.goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziel ist es, ein interaktives Konzept zu erstellen, das die Patienten bei der Selbstpflege unterstützt. Um dies bestmöglich zu erreichen, ist es wichtig, Symptome wie Lustlosigkeit oder Motivationsverlust zu überwinden. Genau hier setzt der Ansatz Gamification an, denn der Einsatz von Spielelementen wird mit der Förderung der Motivation, der Steigerung des Engagements und der Förderung des Wohlbefindens in Verbindung gebracht. Dies wirft die Frage auf, ob Gamification auch depressiven Menschen helfen kann, positive Erfahrungen zu machen und damit die Selbstfürsorge zu unterstützen. In dieser Arbeit liegt der Fokus hauptsächlich auf jungen depressiven Erwachsenen, woraus sich die folgende Forschungsfrage ergibt:"])},
  "betterfly.rq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RQ: Kann der Einsatz von Gamification die Selbstfürsorge bei jungen Erwachsenen, die an Depressionen leiden, fördern?"])},
  "betterfly.periodOfTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April 2021 - Oktober 2021"])},
  "betterfly.type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masterarbeit"])},
  "betterfly.process1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der gesamte Prozess wird nach dem Ansatz des User-Centered Design (UCD) durchgeführt. Der Prozess besteht aus den Phasen Analyse, Design, Evaluation und Implementierung, wobei ein iterativer Prozess stattfindet und somit ähnliche Schritte mehrfach durchlaufen werden. Um mir ein Grundwissen zum Thema Depression und Gamification anzueignen, habe ich mich zu Beginn mit den Grundlagen beschäftigt. In der Analysephase werden die Ziele und Bedürfnisse der Nutzer ermittelt und analysiert. Dies hilft mir dabei, den Nutzer, seinen Nutzungskontext und die Probleme der Stakeholder zu verstehen und somit eine Vision zu entwickeln. Dabei ist es mir wichtig, mich auf die Meinung und Erfahrung der Beteiligten zu stützen, um sicherzustellen, dass Struktur, Inhalt und Design des Konzepts auf den Bedürfnissen, Erwartungen und dem Verständnis der Nutzer basieren. Zur Erhebung und Analyse der Daten wurden Interviews mit Experten und eine Befragung von Betroffenen durchgeführt. Darüber hinaus habe ich einen kleinen Workshop mit Betroffenen durchgeführt, um mehr über ihre Bedürfnisse zu erfahren. So kann ich mich besser in die Betroffenen einfühlen, denn Empathie ist das Herzstück des Designs. Um besser zu verstehen, was sie sehen, fühlen und erleben, wurden Personas und Empathy Maps erstellt sowie User Stories und Anforderungen dokumentiert. Darüber hinaus habe ich in der ersten Phase konkurrierende Produkte analysiert."])},
  "betterfly.process2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf der Grundlage der durchgeführten Analyse startete ich die nächste Phase mit einer Ideation als Methode zur Erkundung aller möglichen Lösungen, bevor die Ideen für die weitere Vorgehensweise eingegrenzt werden. Anschließend erstellte ich eine Informationsarchitektur und einen ersten Low-Fidelity-Prototypen. Mit diesem Entwurf führte ich die ersten Tests durch und ließ es von Experten aus diesem Bereich bewerten. Basierend auf den Ergebnissen der Evaluation erstellte ich dann weitere High-Fidelity-Prototypen in Form von Wireframes, die ich wiederum evaluiert und getestet habe. Sobald ich mit dem Konzept zufrieden war, dokumentierte ich Gestaltungsrichtlinien und erstellte den Style Guide. Auf dieser Grundlage entwarf ich das endgültige Design und damit einen High-Fidelity-Prototypen. Der letzte Schritt bestand darin, erneut Feedback von potenziellen Nutzern und Experten einzuholen, um am Ende ein gut durchdachtes und benutzerfreundliches Konzept zu erhalten."])},
  "betterfly.analyseHeader1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befragung der betroffenen Personen"])},
  "betterfly.analyse1.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um echte Ziele und Anforderungen an das Konzept abzuleiten, ist ein gewisses Verständnis und Einfühlungsvermögen für die Situation depressiver Menschen erforderlich. Eine Befragung der Betroffenen trägt dazu bei, dass sich Struktur, Inhalt und Gestaltung des Konzepts an den Bedürfnissen, Erwartungen und dem Verständnis der potenziellen Nutzer orientieren."])},
  "betterfly.analyse1.2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um die Forschungsergebnisse aus der durchgeführten Umfrage zu ordnen und in Clustern zu sortieren, verwendete ich das 'Affinity Mapping'. Zu diesem Zweck schrieb ich die Antworten der Teilnehmer auf Post-it's und teilte diese in Kategorien ein, um die Ergebnisse zu gruppieren. Auf diese Weise konnten die gesammelten Informationen verständlicher und greifbarer gemacht werden."])},
  "betterfly.analyseHeader2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualitative Interviews mit Experten"])},
  "betterfly.analyse2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da es sich bei dieser Arbeit um ein komplexeres Thema handelt und daher Kontextwissen erforderlich ist, ist es umso wichtiger, die Probleme während der Forschung zu verstehen und einen Überblick zu erhalten. Daher könnte es hilfreich sein, mit einigen Experten auf dem Gebiet zu sprechen, um mehr über mögliche Lösungen für das Problem zu erfahren, wichtige Meinungen zu erfassen und Anforderungen für die nächsten Schritte zu sammeln. Um ein ganzheitliches Verständnis des Problems zu gewährleisten, war es mir wichtig, mit Experten mit unterschiedlichen Perspektiven zu sprechen."])},
  "betterfly.targetgroupHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zielgruppe"])},
  "betterfly.targetgroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um besser zu verstehen, was Menschen sehen, fühlen und erleben, werden Personas und Empathy Maps erstellt, sowie Userstorys und Anforderungen dokumentiert. Auf Basis der Analyse und der durchgeführten Nutzerforschung wurden die Daten für eine Zielgruppendefinition strukturiert und vier fiktive Personen erstellt, die die Zielgruppe dieser Arbeit definieren. Eine breite Basis von qualitativen und quantitativen Daten aus der ersten Phase garantiert fundierte und zuverlässige Ergebnisse."])},
  "betterfly.prototype1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paper Prototype und die erste Evaluierungsrunde"])},
  "betterfly.prototype2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wireframes und die zweite Evaluierungsrunde"])},
  "betterfly.concept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konzept"])},
  "betterfly.functions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktionsübersicht"])},
  "betterfly.conclusion1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die abschließende Evaluation hat gezeigt, dass das Konzept auf dem richtigen Weg ist, von den Betroffenen gut angenommen wird und eine große Unterstützung im Kampf gegen Depressionen sein kann. Der aktuelle Stand des Konzeptes erfüllt bereits alle menschlichen Grundbedürfnisse. Der Erfüllungsgrad des Bedürfnisses Bezogenheit liegt jedoch im mittleren Bereich und ist daher noch ausbaufähig. Verbundenheit mit anderen Menschen ist gerade für depressive Menschen ein wichtiges Bedürfnis und für die Heilung entscheidend. Im weiteren Verlauf ist es daher noch möglich, an der Erhöhung der Bedürfniserfüllung zu arbeiten."])},
  "betterfly.conclusion2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusammenfassend lässt sich sagen, dass der Einsatz von Gamification in der Selbstfürsorge depressiver Jugendlicher durchaus Sinn macht und aufgrund der positiven Effekte der Spielelemente, wie Motivationssteigerung und Engagementförderung, auch eine große Unterstützung in der Behandlung von Depressionen sein kann. Allerdings sollte für den weiteren Verlauf noch beachtet werden, dass diese App kein Ersatz für eine professionelle Therapie sein kann und als Ergänzung dazu gesehen werden muss. Wichtig ist auch zu beachten, dass diese App auch nicht zum Zeitvertreib oder zur Ablenkung genutzt werden sollte. Sie sollte als Aktivator gesehen werden, der die Betroffenen dazu bringt, positive Aktivitäten im realen Leben durchzuführen."])},
  "foodar.about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Arbeit entstand im Sommersemester 2020 meines Studiums User Experience Design (Master of Science). Gemeinsam mit meinem Projektpartner entwickelten wir dieses Konzept im Kurs Interface Design."])},
  "foodar.problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie können wir eine tägliche Aufgabe wie den Lebensmitteleinkauf einfacher und bequemer gestalten? Diese übergreifende Frage stellten wir uns zu Beginn des Projekts und definierten Schmerzpunkte, die in diesem Zusammenhang auftreten, in Form von Unterfragen:"])},
  "foodar.problem.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was koche ich heute?"])},
  "foodar.problem.2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie kann ich den Einkaufswagen schnell entriegeln?"])},
  "foodar.problem.3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In welchem Regal kann ich mein Produkt finden?"])},
  "foodar.problem.4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wo finde ich genauere Informationen über das Produkt?"])},
  "foodar.problem.5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie kann ich den Überblick über die Kosten meines Einkaufs behalten?"])},
  "foodar.problem.6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie kann ich sicher sein, dass ich nichts vergessen habe?"])},
  "foodar.problem.7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kann ich bargeldlos bezahlen?"])},
  "foodar.problem.8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kann ich das Anstehen in der Warteschlange vermeiden?"])},
  "foodar.goal1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unser Ziel war es, das Gesamterlebnis beim Zubereiten und Einkaufen zu verbessern, indem wir Rezeptinspirationen geben, eine einfache Organisation mit Hilfe eines Einkaufsassistenten bieten, interessante Informationen zu Lebensmitteln auf eine neue Art und Weise abrufen können und die Effizienz durch eine eingebaute Navigation in Augmented Reality (AR) erhöhen."])},
  "foodar.goal2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu diesem Zweck haben wir das Konzept FOODAR entwickelt: FOOD + AR (Augmented Reality)"])},
  "foodar.periodoftime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April 2020 - Juli 2020"])},
  "foodar.type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studienprojekt"])},
  "foodar.analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach einer umfangreichen Recherche über Pain Points und Verbesserungspotenziale begannen wir zwei Personas zu erstellen, um unsere Nutzergruppe in den Mittelpunkt des gesamten Prozesses zu stellen. Während die eine Persona die Nutzergruppe der Neugierigen repräsentiert, die mehr Informationen über Produkte erhalten möchten, konzentriert sich die andere Persona auf Nutzer, die die App nutzen möchten, um die Effizienz beim Einkaufen durch eine bessere Organisation zu erhöhen. Für jede Persona wurde ein Nutzerszenario definiert, das die repräsentative Art der Nutzung der App abbildet. Diese stellen die beiden Extreme dar - die typischen realen Nutzer können unterschiedliche Funktionen aus beiden Bereichen nutzen."])},
  "foodar.functionOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersicht der Funktionen"])},
  "foodar.organisationHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation"])},
  "foodar.organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfahren Sie mehr über Lebensmittel und lassen Sie sich zu neuen, leckeren Rezepten inspirieren. Mit den eingebauten einfachen Filteroptionen können Sie die Rezeptsuche leicht anpassen. Sie können die digitale Einkaufsliste jederzeit anpassen und Ihre Listen im Supermarkt durchgehen. Die Kosten werden beim Einkaufen permanent mitverfolgt, so dass Sie immer den Überblick behalten. Vergessen Sie nie wieder ein Produkt - FOODAR wird Sie sofort daran erinnern."])},
  "foodar.information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalisieren Sie FOODAR nach Ihren Wünschen und Bedürfnissen (z.B. Unverträglichkeiten), um immer die richtigen Informationen zu erhalten. Mit der Scan-Funktion erhalten Sie Produktinformationen auf eine völlig neue Art und Weise, wie Sie sie noch nie zuvor erlebt haben - AR macht es möglich. Sie können die Informationen auf dem Bildschirm fixieren und im Detail lesen, was Sie interessiert."])},
  "foodar.efficiencyHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effizienz"])},
  "foodar.efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie schnell und einfach Ihre eigenen Einkaufslisten auf Basis von Rezepten. Im Supermarkt können Sie mit Ihrem Smartphone schnell auf den Einkaufswagen zugreifen. Sie können zu den gewünschten Produkten navigieren und sich eine Übersichtskarte anzeigen lassen. FOODAR zeigt Ihnen den effizientesten Weg durch den Supermarkt und bietet Ihnen die Möglichkeit, schnell und sicher mit Ihrem Smartphone zu bezahlen - ohne Anstehen."])},
  "foodar.iaHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationsarchitektur"])},
  "foodar.ia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im nächsten Schritt überlegten wir uns, wie wir die App logisch organisieren können, damit sie bequem und angenehm zu bedienen ist. Die Struktur der App wurde in einer Informationsarchitektur dargestellt."])},
  "foodar.wireframes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dann begannen wir, unsere Ideen zu visualisieren, indem wir mit Balsamiq Wireframes erstellten, die einen ersten Eindruck vom Bildschirmaufbau vermitteln. Dabei ging es nicht um eine schöne Optik, sondern der Fokus lag auf der Anordnung der Bedienelemente und der Verknüpfung der einzelnen Screens. Dieser erste Satz von Low-Fidelity-Screens wurde dann im nächsten Schritt verlinkt und klickbar gemacht, so dass ein erster Nutzertest durchgeführt werden konnte."])},
  "foodar.usertesting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um unsere Ideen mit potenziellen Nutzern zu evaluieren, wurden zwei Nutzertests mit insgesamt acht Probanden durchgeführt. Den Probanden wurden verschiedene Aufgaben gestellt, die sie innerhalb der App lösen sollten, und anschließend wurden Interviews als qualitative Datenerhebungsmethode durchgeführt. Die Ergebnisse der Tests wurden genutzt, um das Konzept kontinuierlich zu verfeinern und zu verbessern."])},
  "foodar.styleguide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um unsere Ideen und Konzepte zu visualisieren, haben wir ein Moodboard erstellt, das mit Fotos und verschiedenen Texturen gefüllt wurde. Es sollte helfen, die richtige Stimmung zu vermitteln und die Emotionen zu transportieren, die von der App erwartet werden. Es soll dabei unterstützen, Ideen und Begeisterung zu finden und sich davon inspirieren zu lassen. Außerdem bildete sie die Grundlage für die Definition des richtigen Farbschemas und Designstils. Darauf aufbauend haben wir unseren Styleguide definiert, der die Verwendung der Designelemente für ein konsistentes und einheitliches Erscheinungsbild unserer App festlegt."])},
  "foodar.brandDesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Name der App soll bereits auf die beiden Komponenten der App aufmerksam machen. Zum einen die Nutzung im Lebensmittelgeschäft und zum anderen die Augmented Reality Funktion. Daher haben wir uns für den Namen FOODAR (FOOD + AR) entschieden. In der Gestaltung des Logos wird die AR in einer fetteren Schrift dargestellt, um sie zu betonen. Das Logo stellt die Scan-Funktion mit der Kamera dar. Der Rahmen erinnert an die Kamerafunktion. Der Anfangsbuchstabe F von FOODAR ist in diesen Rahmen integriert."])},
  "foodar.finalConcept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finales Konzept"])},
  "foodar.introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei der erstmaligen Nutzung der App wird der Nutzer in die Bestandteile der App eingewiesen. So wird er mit der Struktur vertraut gemacht und weiß, welche Funktionen zur Verfügung stehen und wo sie zu finden sind. Das vermeidet langes Suchen und macht auf den Funktionsumfang aufmerksam, so dass der Nutzer keine wichtigen Möglichkeiten übersieht."])},
  "foodar.inspiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Inspirationsbereich bietet dem Nutzer interessante Informationen rund um das Thema Lebensmitteleinkauf. Interessante und inspirierende Rezepte, Informationen über bestimmte Lebensmittel und deren Herstellung oder eine Einkaufshilfe mit Rezeptvorschlägen sind auf dem Inspirationsbildschirm zu finden. Die Inhalte werden an die persönlichen Vorlieben und Einstellungen des Nutzers angepasst. Sie können auch nach benötigten Rezepten suchen und anhand der Zutaten eine Einkaufsliste erstellen."])},
  "foodar.navigation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Funktionen schaffen ein neues Einkaufserlebnis und unterstützen den Nutzer dabei, seinen Lebensmitteleinkauf effizient zu erledigen. Dieser Bereich wird als Augmented Reality Ansicht dargestellt, was dieses Erlebnis zu etwas Besonderem macht. Es ist möglich, die Navigation zu starten und neue, interessante Informationen zu erfahren. Um mehr Informationen über ein Produkt zu erhalten, wird es einfach gescannt und die Informationen erscheinen auf dem Bildschirm. Die Navigationsfunktion ermöglicht es, zu einem gesuchten Produkt zu navigieren, indem der Weg auf dem Boden abgebildet wird."])},
  "foodar.assistant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der in der App enthaltene Einkaufsassistent unterstützt den Nutzer dabei, den Einkauf auf Basis der erstellten Einkaufslisten effizient und schnell zu erledigen. Dieser Bereich enthält auch die bereits erwähnten Funktionen zum Scannen und Navigieren, die der Nutzer auf Basis dieser Liste nutzen kann."])},
  "foodar.payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit der Bezahlfunktion kann der Einkauf stressfrei und effizient abgeschlossen werden. Bereits gescannte Produkte im digitalen Warenkorb können einfach bezahlt werden. Die App bietet eine Vielzahl von Zahlungsmöglichkeiten, aus denen Sie wählen können. So können Sie Ihren Einkauf abschließen, ohne alle Produkte an die Kasse zu legen und vermeiden lange Warteschlangen."])},
  "foodar.animation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um den Übergang und die Nutzung der App zu verdeutlichen, wurden die Interaktionen animiert und in einem Imagefilm dargestellt. Das Video spiegelt auch den gesamten Umfang des Konzepts wider und veranschaulicht, wie die Funktionen genutzt werden. Für die Navigation und das Einscannen eines Produktes wurden Filmaufnahmen in einem Lebensmittelgeschäft gemacht. Die Komponenten und Animationen der App wurden dann in die Aufnahmen eingebunden und die Funktion abgebildet."])},
  "foodar.conclusion1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusammenfassend gesagt haben wir mit FOODAR ein App-Konzept entwickelt, das die Zukunftstechnologie der Augmented Reality als einzigartigen Aspekt in eine App für eine alltägliche Aufgabe integriert. FOODAR ist sehr inklusiv - jeder Mensch, unabhängig von sozialer Schicht oder persönlichen Vorlieben, kann die App nutzen und sie an seine Bedürfnisse anpassen. FOODAR macht die Aufgabe des Lebensmitteleinkaufs einfacher und bequemer."])},
  "foodar.conclusion2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Überlegungen zur Weiterentwicklung des App-Konzeptes könnten angestellt werden. So können beispielsweise Aspekte wie eine Übersetzungsfunktion, die Produktdetails übersetzt, integriert werden. Dies könnte besonders im Urlaub im Ausland nützlich sein. Darüber hinaus könnten Informationen über den eigenen Verbrauch und Vorrat gegeben werden: 'Dieses Lebensmittel haben Sie noch zu Hause und müssen es nicht noch einmal kaufen.' Alles in allem war es ein interessantes und lehrreiches Projekt. Die Ausarbeitung des Konzepts war manchmal sehr herausfordernd, aber insgesamt hatten wir viel Spaß bei der Umsetzung unserer Ideen."])},
  "contact.about1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das CONTACT-Konzept entstand gemeinsam mit meinem damaligen Kommilitonen Tobias Klotz und in Zusammenarbeit mit der Queensland University of Technology (Studiengang Industriedesign). Im Rahmen eines Studienprojekts im 3.Semester bekamen wir die Aufgabe, basierend auf das technische Konzept der Studenten der QUT ein Produkt mit Gesundheits- und/oder Wellness-Fokus zu entwickeln. So entstand eine App für eine multifunktionale Schuheinlegesohle."])},
  "contact.about2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unser Konzept wurde in Deutschlands führendem Designmagazin PAGE "])},
  "contact.about3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" veröffentlicht."])},
  "contact.problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Großteil der weltweiten Bevölkerung hatte schon einmal Probleme mit den Füßen: ein Fersensporn, eine Entzündung der Fußsohlensehne oder Fußpilz sind keine Seltenheit und können den Bewegungsablauf und die Lebensqualität sehr beeinträchtigen. Diese Fußkrankheiten gehen häufig auf einfach zu vermeidende Probleme zurück: zu enges Schuhwerk, falsche Belastung oder zu viele Bakterien im Schuh."])},
  "contact.periodOfTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 Monate"])},
  "contact.type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studienprojekt"])},
  "contact.conceptHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Konzept"])},
  "contact.concept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die CONTACT App hilft dabei dem Nutzer sein Fitness-Programm durchzuführen und Erfolge festzuhalten. Der Running-Modus begeistert zusätzlich durch eine Audio-Ausgabe der Live-Analyse, damit der Nutzer sich voll und ganz auf seinen Run konzentrieren kann und nicht auf das Smartphone blicken muss. Und nach einem anstrengenden Run belohnt man sich selber mit der Massage-Funktion der Einlegesohle oder der eingebauten Fußheizung, wenn es im Winter mal wieder kalt an den Füßen ist."])}
}