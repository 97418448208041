
  import coreSkills from "../assets/json/coreSkills.json";
  import { defineComponent } from 'vue';

  export default defineComponent({
  data() {
    return {
      coreSkills: coreSkills.coreSkills
    }
  }
});
