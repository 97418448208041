
import { defineComponent } from 'vue'
import MainMenu from "@/components/MainMenu.vue"
import FooterComp from "./components/FooterComp.vue"

export default defineComponent({
  name: 'App',

  components: {
    MainMenu,
    FooterComp
},

  data () {
    return {
      //
    }
  },
})
