// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify( {
  theme: {
    themes: {
      light: {
        colors: {
          primary: '#364050',
          secondary: '#b0bec5',
        }
      },
    },
  },
}
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  
)
