export default {
  "menu.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "menu.projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
  "menu.about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
  "menu.contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "home.hello1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello 👋🏼, I'm Benjamin Homm - UX/UI Designer with many years of experience in the fields of App and Web."])},
  "home.hello2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I design applications/websites and implement them with state-of-the-art technologies. The result are digital products that cover your requirements and business goals and are gladly used by many."])},
  "home.conceptOfSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My concept for success"])},
  "home.tech1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My"])},
  "home.techBold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" technical know-how "])},
  "home.tech2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enables me to put your project directly into practice."])},
  "home.needs1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Develop understanding of"])},
  "home.needsBold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" user needs and capabilities "])},
  "home.needs2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to design effective systems."])},
  "home.business1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My solutions are always in line with your "])},
  "home.businessBold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" goals and business strategies."])},
  "home.aboutMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About me"])},
  "home.aboutMe1Bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over 5 years of professional experience "])},
  "home.aboutMe1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["as a UX/UI-Designer."])},
  "home.aboutMe2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Years of experience with "])},
  "home.aboutMe2Bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["agile project management."])},
  "home.aboutMe3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Years of experience with "])},
  "home.aboutMe3Bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["interdisciplinary teams."])},
  "home.briefInsight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A brief insight into my work"])},
  "home.aboutMyProjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About my projects"])},
  "home.projectFlow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Flow"])},
  "process.step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 1"])},
  "process.title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Project Request"])},
  "process.description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Through the inquiry I get a basic understanding of the project. The more info I get at the beginning, the better I can judge if I am the right partner for you."])},
  "process.step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 2"])},
  "process.title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The First Meeting"])},
  "process.description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the first meeting, we get to know each other and discuss the goals, requirements and general conditions. We also clarify questions and define the next steps."])},
  "process.step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 3"])},
  "process.title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Offer"])},
  "process.description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When both parties have a clear idea of the conditions and goals of the project, I prepare an individual offer, which we then discuss and go through together."])},
  "process.step4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 4"])},
  "process.title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Implementation"])},
  "process.description4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once the offer is accepted, I start the implementation and complete the previously discussed project phases. Regular updates throughout the process are important to me."])},
  "skills.mySkills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Skills"])},
  "skills.coreCompetencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following points are among my core competencies"])},
  "tools.favoriteTools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These are my favorite tools for the creative process"])},
  "footer.lookingForward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am looking forward to your project."])},
  "footer.impress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
  "footer.privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
  "projects.selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here is a selection of my work"])},
  "projects.furtherWork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other projects worth mentioning"])},
  "projects.secrecy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secrecy"])},
  "projects.project1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App design for a large german discounter"])},
  "projects.project2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web application for HR/personnel management"])},
  "projects.project3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web application for occupational health and safety"])},
  "projects.project4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App to improve the workflow in the company - Bachelor Thesis"])},
  "projects.project5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web application for user management for an automotive manufacturer"])},
  "projects.project6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Improving the UX for Users of Automated Shuttle Buses in Public Transport: Investigating Aspects of Exterior Communication and Interior Design"])},
  "main.moreAbout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More about me"])},
  "main.projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
  "about.p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My name is Benjamin and I am a UX designer from Ingolstadt. I was able to acquire my skills and knowledge in this area through my studies at the Technical University in Ingolstadt. Here I made my "])},
  "about.p1Bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bachelor and Master of Science in User Experience Design"])},
  "about.p2.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["During and after my studies I worked as a "])},
  "about.p2Bold1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["software developer and UX designer "])},
  "about.p2.2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" for a medium-sized company. Here I was part of the UX team for a total of 5 years and was responsible for the "])},
  "about.p2Bold2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["design strategy and conception, as well as the front-end development "])},
  "about.p2.3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of web applications, among other things."])},
  "about.p3.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaboration and communication with interdisciplinary teams was also important here. Through these experiences in my professional life, I can put myself well into the "])},
  "about.p3Bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["perspectives of developers and designers "])},
  "about.p3.2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and can thus combine user-centered design with technologies."])},
  "about.p4.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have been working as a freelance UX/UI designer since October 2022 and moved to intive GmbH in June 2023, where I worked as a "])},
  "about.p4Bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UX designer for the development of an app "])},
  "about.p4.2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for a large german discounter."])},
  "about.myCV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My CV"])},
  "about.career": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education and professional career"])},
  "cv.intive.year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["06/2023 - 10/2023"])},
  "cv.intive.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UX Designer"])},
  "cv.intive.header1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Design"])},
  "cv.intive.bullet1.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wireframing & Prototyping"])},
  "cv.intive.bullet1.2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interface Design"])},
  "cv.intive.bullet1.3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design System"])},
  "cv.freelance.year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10/2022 - 10/2023"])},
  "cv.freelance.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freelancer"])},
  "cv.freelance.bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Research / Qualitative & Quantitative Research"])},
  "cv.freelance.bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wireframing & Prototyping"])},
  "cv.freelance.bullet3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interface / Interaction Design"])},
  "cv.uxprosis.year1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11/2021 - 10/2022"])},
  "cv.uxprosis.year2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["03/2019 - 03/2020"])},
  "cv.uxprosis.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UX Designer"])},
  "cv.uxprosis.header1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UX/UI-Design"])},
  "cv.uxprosis.bullet1.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interface Design"])},
  "cv.uxprosis.bullet1.2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guidelines & Design System"])},
  "cv.uxprosis.bullet1.3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icon Design"])},
  "cv.uxprosis.header2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frontend Development"])},
  "cv.uxprosis.bullet2.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Application Development (JSF / Vue.js)"])},
  "cv.uxprosis.bullet2.2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development and Management of the theme"])},
  "cv.master.year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["03/2020 - 11/2021"])},
  "cv.master.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master of Science - User Experience Design"])},
  "cv.master.header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses"])},
  "cv.master.bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Research Methods in HCI, Programming for Multimodal Interactive Systems, Mobile App Development"])},
  "cv.master.bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interaction Design, Natural User Interfaces, Design Strategy and Management, Interface Design"])},
  "cv.master.bullet3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agile Project Management, Audio/Video Processing und 3D-Animation, Augmented und Virtual Reality Applications"])},
  "cv.scrum.year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["01/2020"])},
  "cv.scrum.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foundation in agile project management according to SCRUM"])},
  "cv.scrum.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3-day training with examination"])},
  "cv.student.year1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["03/2020 - 11/2021"])},
  "cv.student.year2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["09/2017 - 03/2019"])},
  "cv.student.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working Student - UX Designer"])},
  "cv.bachelor.year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10/2015 - 03/2019"])},
  "cv.bachelor.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bachelor of Science - User Experience Design"])},
  "cv.bachelor.header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses"])},
  "cv.bachelor.bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webdesign & Webusability, Designing Interactive Products, Product Design"])},
  "cv.bachelor.bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software Prototyping & Usability Testing, Information Psychology, Design of Human-Machine Interfaces"])},
  "cv.bachelor.bullet3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software Development, Web Technologies, Software Engineering, Human-Machine Interaction Technology, Virtual & Augmented Reality"])},
  "cv.bachelor.bullet4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics, Business administration basics and personnel organization, Soft Skills, Marketing, Project Management"])},
  "project.main.about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
  "project.main.goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal"])},
  "project.main.role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
  "project.main.period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period of time"])},
  "project.main.type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "project.main.analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis"])},
  "betterfly.about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This thesis deals with the concept and design of a mobile application to support young adults with depression. The goal of this application is to support affected individuals in self-care in the best possible way through the use of gamification. The entire process is carried out according to the User-Centered Design approach (UCD)."])},
  "betterfly.problem1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There's a list of things I know I can do to make myself feel like I've accom- plished something, to make myself feel better both physically and mentally. The problem is, in the throes of depression, these things feel like an impossible chore."])},
  "betterfly.problem2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The quote from a depressed woman shows that most sufferers are well aware that self- care is important in order to overcome depression and thus become healthy again. On the one hand, things must be removed from one's life that are harmful, and on the other hand, it is important to treat oneself to things that do one good. Here comes the problem, because many sufferers do not find it easy to say what is personally good for them and what alleviates their bad mood. In addition, depressed people suffer from listlessness and also loss of joyfulness and interest. Being depressed goes far beyond being in a bad mood and leads to no longer seeing any sense in activities and feeling helpless."])},
  "betterfly.goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The goal is to create an interactive concept that supports patients in self-care. To achieve this in the best possible way, it is important to overcome symptoms such as listlessness or loss of motivation. This is exactly where the approach of gamification comes in, because the use of game elements is associated with the pro- motion of motivation, the increase of engagement and the promotion of well-being. This raises the question of whether gamification can also help depressed people to have a positive experience and thereby support self-care. In this thesis, the focus is mainly on young depressed adults and for this the following research question arises:"])},
  "betterfly.rq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RQ: Can the use of gamification promote self-care in young adults suffering from depression?"])},
  "betterfly.periodOfTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April 2021 - October 2021"])},
  "betterfly.type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master's Thesis"])},
  "betterfly.process1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The entire process is carried out according to the User-Centered Design approach (UCD). UCD is a design process that focuses on human needs and capabilities, and tailors the design accordingly [50]. This is an important point, because the user is the one who interacts with the system in the end. The process consists of the phases analysis, design, evaluation, and implementation, whereby an iterative process takes place and thus similar steps are run through several times. In order to acquire basic knowledge on the subject of depression and gamification, the basics are dealt with at the beginning and research is carried out in these areas. In the analysis phase, the goals and needs of the users are identified as well as analyzed. This helps to understand the user, his context of use and to understand the problems of the stakeholders and thus set a vision. For this, it is important to rely on the opinion and experience of stakeholders to ensure that the structure, content and design of the concept are based on the needs, expectations and understanding of the users. Interviews with experts and a survey of affected persons are conducted to collect and analyze the data. In addition, a small workshop will be conducted with affected persons to learn more about their needs. Based on the data collected, it is crucial to empathize, as empathy is at the heart of the design. To better understand what they see, feel and experience, personas and empathy maps are created, and user stories and requirements are documented. In addition, competing products are analyzed in the first phase."])},
  "betterfly.process2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Based on the conducted analysis, the next phase starts with ideation as a method to explore all possible solutions before narrowing down to the ideas to proceed with. This step starts with brainstorming concepts and creating sketches. It continues with creating an information architecture and designing with paper and pencil to create low-fidelity prototypes. Prototypes serve as the basis for usability testing, which can be used to refine the concept and also improve and optimize it over time. It is important to validate the concept with the target audience early on and therefore conduct usability testing and include expert evaluation. Based on the findings of the evaluation, further high-fidelity prototypes are then created in the form of wireframes, which are then evaluated and tested again. Once a stable concept is in place, design guidelines can be documented and a design system created. Based on this, a final design and thus a high-fidelity prototype is created. The final step is to gather feedback again from potential users and experts to end up with a well thought-out and user-friendly concept."])},
  "betterfly.analyseHeader1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survey of affected persons"])},
  "betterfly.analyse1.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to derive real goals and requirements for the concept, a certain understanding and empathy for the situation of depressed people is needed. A survey of affected people will help to ensure that the structure, content and design of the concept are based on the needs, expectations and understanding of potential users."])},
  "betterfly.analyse1.2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To organize and sort the research findings from the conducted survey into clusters, the method 'Affinity Mapping' was used. For this purpose, the participants' answers were written on sticky notes and then divided into categories in order to group the findings. This way the collected information could be made more understandable and tangible."])},
  "betterfly.analyseHeader2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualitative Interviews with Experts"])},
  "betterfly.analyse2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Since this work is a more complex topic and therefore the context knowledge is necessary, it is even more important to understand the problems during the research and to obtain a coherent overview. Therefore it might be helpful to talk to some experts in the field to learn more about possible solutions to the problem, to capture important opinions and to gather requirements for the next steps. To ensure a holistic understanding of the problem it is important to talk to experts with different perspectives."])},
  "betterfly.targetgroupHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target Group"])},
  "betterfly.targetgroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to better understand what people see, feel and experience, personas and empathy maps are created, and userstory's and requirements are documented. Based on the analysis and the conducted user research, the data for a target group definition was structured and four fictitious persons were created, which define the target group of this work. A broad basis of qualitative and quantitative data from the first phase guarantees well-founded and reliable results."])},
  "betterfly.prototype1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paper Prototype and the first round of evaluation"])},
  "betterfly.prototype2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wireframes and the second round of evaluation"])},
  "betterfly.concept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concept"])},
  "betterfly.functions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Function Overview"])},
  "betterfly.conclusion1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The final evaluation has shown that the concept is on the right track and is well accepted by those affected and can be a great support in the fight against depression. The current state of the concept already fulfills all basic human needs. However, the extent to which the need Relatedness is fulfilled is in the middle range and therefore still expandable. Relatedness to other people is an important need especially for depressed people and is crucial in healing. In the further course, it is therefore still possible to work on increasing the fulfillment of the need."])},
  "betterfly.conclusion2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In summary, it can be said that the use of gamification in the self-care of depressed young people makes perfect sense and can also be a great support in the treatment of depression due to the positive effects, such as increased motivation and the promotion of engagement, of the game elements. However, it should still be noted for the further course that this app can not be a replacement for a professional therapy and must be seen as an addition to it. It is also important to note that this app should also not be used to pass the time or as a distraction. It should be seen as an activator that gets sufferers to perform positive activities in real life."])},
  "foodar.about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This work was created in the summer semester 2020 of my studies User Experience Design (Master of Science). Together with my project partner we developed this concept in the Interface Design course."])},
  "foodar.problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How can we make a daily task like grocery shopping easier and more convenient? We asked ourselves this overarching question at the beginning of this project and defined pain points that arise in this context in form of sub-questions:"])},
  "foodar.problem.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What am I cooking today?"])},
  "foodar.problem.2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How can I quickly unlock the shopping cart?"])},
  "foodar.problem.3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In which shelf can I find my product?"])},
  "foodar.problem.4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where can I find more detailed information about the product?"])},
  "foodar.problem.5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How can I keep track of the cost of my purchase?"])},
  "foodar.problem.6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How can I be sure that I haven't forgotten anything?"])},
  "foodar.problem.7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can I pay cashless?"])},
  "foodar.problem.8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can I avoid standing in the queue?"])},
  "foodar.goal1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our goal was to improve the overall experience when preparing and making purchases by giving recipe inspirations, offering an easy organization with the help of a shopping assistant, the ability to call up interesting information about groceries in a new way and increased efficiency through a built-in navigation in augmented reality (AR)."])},
  "foodar.goal2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For this purpose, we developed the app concept FOODAR: FOOD + AR (Augmented Reality)"])},
  "foodar.periodoftime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April 2020 - Juli 2020"])},
  "foodar.type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study Project"])},
  "foodar.analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After an extensive research about pain points and potentials for improvement we started to create two personas to put our user group at the center in the whole process. While one persona represents the user group of curious people who want to get more information about products, the other persona focuses on users who want to use the app to increase the efficiency when shopping through a better organization. For each persona a user scenario was defined which illustrates the representative way of using the app. These represent the two extremes – the typical real users can use different functions from both areas."])},
  "foodar.functionOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Functions Overview"])},
  "foodar.organisationHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization"])},
  "foodar.organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more about food and get inspiration for new, delicious recipes. With the built-in simple filter options, you can easily adjust the recipe search. You can adapt the digital shopping list at any time and go through your lists in the supermarket. The costs are tracked permanently while shopping, so that you always keep an overview. Never forget a product again - FOODAR will remind you of it immediately."])},
  "foodar.information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalize FOODAR according to your wishes and needs (e.g. intolerances) to always get the right information. With the scanning function, you receive product information in a completely new way, as you have never experienced products before - AR makes it possible. You can fix information on the screen and read in detail what you are interested in."])},
  "foodar.efficiencyHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efficiency"])},
  "foodar.efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your own shopping lists quickly and easily on basis of recipes. At the supermarket, you can get quick access to the shopping cart with your smartphone. You can navigate to the desired products and view an overview map. FOODAR shows you the most efficient way through the supermarket and gives you the opportunity to pay quickly and securely on your smartphone - without queuing."])},
  "foodar.iaHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information Architecture"])},
  "foodar.ia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the next step, we considered how to organize the app logically so that it would be convenient and pleasant to use. The structure of the app was represented in an information architecture."])},
  "foodar.wireframes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Then we started to create wireframes with the help of Balsamiq. In this way, we were able to get a first impression of the structure and visualize our ideas. It was not about a beautiful appearance, but the focus was on the structure, the arrangement of the control elements and the linking of the individual screens. This first set of low-fidel- ity-screens was then linked and made clickable in the next step so that a first user test could be carried out "])},
  "foodar.usertesting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To evaluate our ideas with potential users, two user tests were conducted with a total of eight subjects. The test subjects were given various tasks to solve within the app, and interviews were then conducted as a qualitative data collection method. The results of the tests were used to continuously refine and improve the concept."])},
  "foodar.styleguide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to visualize our ideas and concepts, we created a moodboard, which was filled with photos and various textures. It should help to transmit the right mood and convey the emotions that are expected from the app. It should also make it easier to find ideas and enthusiasm and be inspired by it. Addition- ally, it formed the basis for the definition of the right color scheme and style of design. Based on this, we defined our styleguide, which defines the use of the design elements for a consistent and uniform appearance of our app."])},
  "foodar.brandDesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The name of the app should already draw attention to the two components of the app. On the one hand the use in the grocery store and on the other hand the augmented reality function. Therefore, we decided to name it FOODAR (FOOD + AR). In the design of the logo, the AR is shown in a bolder font to emphasize it. The logo represents the scanning-function with the camera. The frame is reminiscent of the camera-function. The starting letter F from FOODAR is integrated in this frame."])},
  "foodar.finalConcept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Concept"])},
  "foodar.introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When using the app for the first time, the user is briefed on the components of the app. This way he is familiarized with the structure and knows which functions are available and where to find them. This avoids long searches and draws attention to the range of functions, so that the user does not miss important capabilities."])},
  "foodar.inspiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The inspiration section provides the user interesting information about the topic of grocery shopping. Interesting and inspiring recipes, information about certain foods and their produc- tion or a shopping aid with recipe suggestions can be found on the inspiration screen. The content is adapted to the personal preferences and settings of the user. You can also search for needed recipes and create a shopping list based on the ingredients."])},
  "foodar.navigation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These functions create a new shopping experience and support the user in carrying out his grocery shopping efficiently. This area is shown as an augmented reality view, which makes this experience something special. It is possible to start the navigation and to find out new, interesting information. To get more information about a product, it is just simply scanned, and the information will appear on the screen. The navigation function allows to navigate to a product that you are looking for by mapping the path on the ground."])},
  "foodar.assistant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The shopping assistant included in the app supports the user to complete the purchase efficiently and quickly based on the created shopping lists. This area also contains the already mentioned functions for scanning and navigation, that the user can use on the basis of this list."])},
  "foodar.payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With the payment function the purchase can be completed stress-free and efficiently. Already scanned products in the digital shopping cart can be easily paid. The app offers a variety of payment options you can choose from. In this way, you can complete your purchase without putting all the products on the cash register and avoid waiting in long queues."])},
  "foodar.animation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to clarify the transition and the use of the app, the interactions were animated and shown in an image film. The video also reflects the entire scope of the concept and illustrates how the functions are used. For the navigation and the scanning of a product, film recordings were made in a grocery store. The components and animations of the app were then included in the recordings and the function was mapped."])},
  "foodar.conclusion1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In summary, with FOODAR we have developed an app concept that integrates the future technology of augmented reality as a unique aspect in an app for an everyday task. FOODAR is very inclusive - anyone, regardless of social class or personal preferences, can use the app and customize it to their needs. FOODAR makes the task of grocery shopping easier and more convenient."])},
  "foodar.conclusion2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Further considerations regarding the further development of the app concept could be carried out. For example, aspects such as a translating function that translates product details can be integrated. This could be particularly useful on vacation abroad. In addition, information about your own consumption and stock could be given: 'You still have this food at home and you do not have to buy it again.' All in all, it was an interesting and educational project. Working out the concept was very challenging sometimes, but overall we had a lot of fun implementing our ideas."])},
  "contact.about1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The CONTACT concept was developed together with my fellow student Tobias Klotz and in collaboration with the Queensland University of Technology (Industrial Design course). As part of a study project in the 3rd semester, we were given the task of developing a product with a health and/or wellness focus based on the technical concept of the QUT students. The result was an app for a multifunctional shoe insole."])},
  "contact.about2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our concept was published in Germany's leading design magazine "])},
  "contact.about3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" PAGE"])},
  "contact.problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A large part of the world's population has had problems with their feet at some point: a heel spur, inflammation of the plantar tendon or athlete's foot are not uncommon and can have a very negative impact on movement and quality of life. These foot diseases often stem from easily preventable problems: shoes that are too tight, incorrect weight-bearing or too many bacteria in the shoe."])},
  "contact.periodOfTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 months"])},
  "contact.type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study Project"])},
  "contact.conceptHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Concept"])},
  "contact.concept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The CONTACT app helps the user to carry out his fitness program and record successes. The running mode also features an audio output of the live analysis, so that the user can fully concentrate on his run and does not have to look at the smartphone. And after a strenuous run, you can reward yourself with the massage function of the insole or the built-in foot heater when your feet get cold in the winter."])}
}