
import { defineComponent } from 'vue';
import toolbox from "../assets/json/toolbox.json";

export default defineComponent({

data() {
    return {
        tools: toolbox.tools
    }
}
});
