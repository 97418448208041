<template>
  <div>
    <!-- Port Landing -->
    <v-container class="port-landing-container d-none d-md-flex">
      <div class="port-landing-left pr-10">
        <h1 class="a">UX/UI - Designer</h1>

        <p class="pt-6">
          {{ $t("home.hello1") }}
        </p>
          
        <p class="pb-6">
          {{ $t("home.hello2") }}
        </p>
        <v-btn rounded="pill" color="primary" 
               size="large" :to="{name: 'about'}">
          {{ $t("main.moreAbout") }}
        </v-btn>
      </div>

      <div class="port-landing-right">
        <h2 class="headline port-landing-heading">{{ $t("home.conceptOfSuccess") }}</h2>
        <!-- Tech -->
        <div class="port-landing-circle port-circle-tech">
          <span>Tech</span>
        </div>
        <div class="port-text-tech port-landing-circle-label">
          <p class="font-weight-regular">
            {{ $t("home.tech1") }} <b>{{ $t("home.techBold") }}</b> {{ $t("home.tech2") }}
          </p>
        </div>
        <!-- User Needs -->
        <div class="port-landing-circle port-circle-needs">
          <span>User Needs</span>
        </div>
        <div class="port-text-needs port-landing-circle-label">
          <p class="font-weight-regular">
            {{ $t("home.needs1") }} <b>{{ $t("home.needsBold") }}</b> {{ $t("home.needs2") }}
          </p>
        </div>
        <!-- Business Goals -->
        <div class="port-landing-circle port-circle-business">
          <span>Business Goals</span>
        </div>
        <div class="port-text-business port-landing-circle-label">
          <p class="font-weight-regular">
            {{ $t("home.business1") }} <b>{{ $t("home.businessBold") }}</b>
          </p>
        </div>
      </div>
    </v-container>

    <!-- Mobile Version -->
    <v-container class="d-md-none margin-b-regular mt-10 text-center">
      <h1 class="text-h3 font-weight-bold port-mobile-heading">UX/UI - Designer</h1>

      <p class="pt-10">
        {{ $t("home.hello1") }}
      </p>
        
      <p class="pb-10">
        {{ $t("home.hello2") }}
      </p>
      <v-btn rounded="pill" color="primary" 
              size="large" :to="{name: 'about'}">
          {{ $t("main.moreAbout") }}
      </v-btn>
    </v-container>
  </div>

  <div id="trigger"></div>

  <!-- About me -->
  <div class="grey-background mb-16" 
       id="aboutMe">
    <v-container id="aboutMe-content">
      <v-row>
        <v-col cols="12" md="6" class="p-transition-y"
               style="margin: auto">
          <h2 class="headline mb-10 text-left">{{ $t("home.aboutMe") }}</h2>

          <ul class="ml-5">
            <li class="mb-6">
              <p>
                <b>Bachelor & Master of Science</b> in User Experience Design.
              </p>
            </li>
            <li class="mb-6">
              <p>
                <b>{{ $t("home.aboutMe1Bold") }}</b> {{ $t("home.aboutMe1") }}
              </p>
            </li>
            <li class="mb-6">
              <p>
                {{ $t("home.aboutMe2") }} <b>{{ $t("home.aboutMe2Bold") }}</b>
              </p>
            </li>
            <li>
              <p>
                {{ $t("home.aboutMe3") }} <b>{{ $t("home.aboutMe3Bold") }}</b>
              </p>
            </li>
          </ul>
          <v-btn rounded="pill" color="primary" 
                size="large" :to="{name: 'about'}"
                class="my-10">
            {{ $t("main.moreAbout") }}
          </v-btn>
        </v-col>

        <v-col cols="12" md="6">
          <img :src="require('../assets/img/me/wireframes-discussion.webp')" alt="me"
               class="p-transition-y about-home-img"/>
        </v-col>
      </v-row>
    </v-container>
  </div>

  <!-- Latest Work -->
  <div class="p-transition-y margin-y-large">
    <v-container>
      <h2 class="headline">{{ $t("main.projects") }}</h2>
      <h3 class="subtitle">{{ $t("home.briefInsight") }}</h3>
    </v-container>
    <div class="mb-16 text-center" 
        style="overflow: hidden;">
      <div id="latestWorkFirstRow" class="latest-work-row lw-first-row mb-10">
        <div v-for="work in latestWork" :key="work.id" class="latest-work ma-2">
          <v-img cover :src="require('../assets/img/' + work.img)" height="100%" 
                 @click="$router.push({ name: work.id })"></v-img>
        </div>
      </div>

      <v-btn id="showAllProjectsBtn" 
            rounded="pill" 
            color="primary"
            size="large" :to="{name: 'work'}">
          {{ $t("home.aboutMyProjects") }}
      </v-btn>
    </div>
  </div>

  <!-- Ablauf -->
  <!-- <div class="port-process grey-background">
    <v-container>
      <h2 class="headline mt-10">{{ $t("home.projectFlow") }}</h2>

      <div class="skill-box process-box"
           v-for="pr in process" :key="pr.id"
           :id="pr.id">
        <p class="text-body-1 text-medium-emphasis">{{ $t(pr.step) }}</p>
        <h3 class="heading mt-n1 mb-3">{{ $t(pr.title) }}</h3>
        <p class="port-process-description">
          {{ $t(pr.description) }}
        </p>
      </div>
    </v-container>
  </div> -->

  <div id="skills" class="margin-y-large">
      <v-container>
        <CoreSkills/>    
        <ToolboxComp/>
      </v-container>
  </div>
</template>


<script>
import { defineComponent } from 'vue';
import latestWorkJson from "../assets/json/latestWork.json";
import processJson from "../assets/json/process.json";
import CoreSkills from "@/components/CoreSkills.vue";
import ToolboxComp from "@/components/ToolboxComp.vue";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default defineComponent({
  name: 'HomeView',

  data() {
    return {
      latestWork: latestWorkJson.latestWork,
      process: processJson.process
    }
  },

  components: {
    CoreSkills,
    ToolboxComp
  },

  mounted() {
    // ------------------------------------------
    // Animation circles
    const tl = gsap.timeline({
        scrollTrigger: {
          trigger: "#trigger",
          start: "top bottom",
          end: "top center",
          scrub: 1,
          pin: ".port-landing-container",
        }
    }).duration(10);

    tl.to(".port-landing-left", {x: "-100px" , opacity: 0, duration: .2});
    tl.to(".port-circle-needs", {duration: .7, rotation: 360, left: 0, top: "50%", width: "300px", height: "300px", transform: "translate(0, -50%)"}, 0);
    tl.to(".port-circle-business", {duration: .7, rotation: 360, left: "50%", top: "50%", transform: "translate(-50%, -50%)"}, 0);
    tl.to(".port-circle-tech", {duration: .7, rotation: 360, right: 0, width: "300px", height: "300px", top: "50%", transform: "translate(0, -50%)"}, 0);
    tl.to(".port-circle-tech span", {duration: .7, marginRight: "auto"}, 0);
    tl.to(".port-landing-circle-label", {duration: .5, opacity: 1, y: 20, visibility: "visible"}, .5);
    tl.to(".port-landing-heading", {duration: .5, opacity: 1, y: 20, visibility: "visible"}, .5);

    // ------------------------------------------
    // Animation latest work
    const latestWorkTL = gsap.timeline({
      scrollTrigger: {
          trigger: "#latestWorkFirstRow",
          endTrigger: "#showAllProjectsBtn",
          start: "top bottom",
          end: "top top",
          scrub: 1
      }
    });

    latestWorkTL.to(".lw-first-row", {x: "-40%", rotation: 1.5}, 0);

    // ------------------------------------------
    // Process
    const process = gsap.timeline({
        scrollTrigger: {
          trigger: ".port-process",
          start: "top 81px",
          end: "bottom 81px",
          pin: ".port-process",
          scrub: 1,
        }
    }).duration(10);

    process.to("#process-1", {duration: 2, scale:.98, y: "-15", ease: "easeOut"}, 1.5);
    process.from("#process-2", {duration: 2, opacity: 0, top: "100%", ease: "easeOut"}, 1);

    process.to("#process-1", {duration: 2, scale:.96, y: "-30", ease: "easeOut"}, 4.5);
    process.to("#process-2", {duration: 2, scale:.98, y: "-15", ease: "easeOut"}, 4.5);
    process.from("#process-3", {duration: 2, opacity: 0, top: "100%", ease: "easeOut"}, 4);

    process.to("#process-1", {duration: 2, scale:.94, y: "-45", ease: "easeOut"}, 7.5);
    process.to("#process-2", {duration: 2, scale:.96, y: "-30", ease: "easeOut"}, 7.5);
    process.to("#process-3", {duration: 2, scale:.98, y: "-15", ease: "easeOut"}, 7.5);
    process.from("#process-4", {duration: 2, opacity: 0, top: "100%", ease: "easeOut"}, 7);

    // Transition
    const cards = gsap.utils.toArray('.p-transition-y')

    cards.forEach((text) => {
        gsap.from(text, {
            scrollTrigger: {
                trigger: text,
                toggleActions: "restart none none reverse",
                start: "-150px 90%",
                end: "-150px 90%"
            },
            y: 150, opacity: 0, duration: 1
        })
    })
  },
});

</script>
