<template>
  <nav align="center">
    <v-app-bar app height="80" elevation="0">
      <v-container>
        <v-row align="center" class="ma-0">
          <v-app-bar-title>
            <div class="port-nav-home" @click="$router.push({ name: 'home' })" >
              <div class="port-nav-title">
                BENJAMIN HOMM
              </div>
              <div>
                UX Designer
              </div>
            </div>
          </v-app-bar-title>

          <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer" class="d-md-none">
          </v-app-bar-nav-icon>

          <div class="d-none d-md-flex">
            <!-- Right side content -->
            <router-link :to="{name: 'home'}">{{ $t("menu.home") }}</router-link>
            <router-link :to="{name: 'work'}" class="ml-5">{{ $t("menu.projects") }}</router-link>
            <router-link :to="{name: 'about'}" class="ml-5">{{ $t("menu.about") }}</router-link>

            <a class="ml-5"  v-on:click="scrollToContact">{{ $t("menu.contact") }}</a>

            <v-divider vertical></v-divider>

            <v-select v-model="$i18n.locale" class="locale-select"
                      :change="storeLocale($i18n.locale)" 
                      :items="locales" item-title="label" item-value="id"
                      density="compact" variant="solo"></v-select>
          </div>
          
        </v-row>
      </v-container>
    </v-app-bar>
  </nav>

  <v-navigation-drawer v-model="drawer"
                       temporary
                       location="right"
                       class="d-md-none port-drawer">
        <v-list nav>
          <v-list-item :title="$t('menu.home')" value="home" @click="$router.push({ name: 'home' })"></v-list-item>
          <v-list-item :title="$t('menu.projects')" value="work" @click="$router.push({ name: 'work' })"></v-list-item>
          <v-list-item :title="$t('menu.about')" value="about" @click="$router.push({ name: 'about' })"></v-list-item>
          <v-list-item :title="$t('menu.contact')" value="contact" @click="scrollToContact"></v-list-item>
        </v-list>      
        <template v-slot:append>
            <v-select v-model="$i18n.locale" class="locale-select"
                      :change="storeLocale($i18n.locale)" 
                      :items="locales" item-title="label" item-value="id"
                      density="compact" variant="solo"></v-select>
          </template>
  </v-navigation-drawer>
</template>

<script>
  import $ from 'jquery'
  export default {
    data () {
      return {
        drawer: null,
        locales: [
          { label: '🇩🇪 DE', id: 'de'},
          { label: '🇬🇧 EN', id: 'en'},
        ]
      }
    },

    methods: {
      scrollToContact() {
        $("html, body").animate({ scrollTop: document.body.scrollHeight}, "slow");
        this.drawer = false;
      },

      storeLocale(locale) {
        localStorage.setItem('lang', locale);
      }
    },
  };
</script>